<template>
  <div v-frag>
    <hero-title title="mainNavBar.tracking" subtitle=" "></hero-title>
    <v-container class="my-5">
      <v-row justify="center">
        <v-col col="12" sm="10">
          <v-card style="overflow: hidden;">
            <v-card-text class="pa-0">
              <v-row class="ma-0">
                <v-col cols="12" md="6" class="pa-0">
                  <v-img :src="`${publicPath}images/contact-form-bg.jpg`" class="align-stretch" cover
                         height="100%" aspect-ratio="1.333" position="center center">
                    <div class="d-flex flex-column justify-center px-6" style="height: 100%;">
                      <!--div class="white--text text-center headline align-self-center mb-2">
                        {{ $t('dearCustomes') }}
                      </div>
                      <div class="white--text text-center body-1 align-self-center">
                        {{ $t('loginWelcomeText') }}
                      </div>
                      <div class="white--text text-center headline align-self-center mt-8 mb-2">
                        {{ $t('toRegUsers') }}
                      </div>
                      <div class="white--text text-center body-1 align-self-center" v-html="$t('toReguserText')"></div-->
                      <div class="white--text text-center title align-self-center mt-8 mb-2">
                        {{ $t('ourEmail') }}
                      </div>
                      <div class="white--text text-center body-1 align-self-center">
                        <a href="mailto:info@tmx.hu" style="color: #FFFFFF;">info@tmx.hu</a>
                      </div>
                      <div class="white--text text-center title align-self-center mt-8 mb-2">
                        {{ $t('ourPhoneNumber') }}
                      </div>
                      <!--<div class="white--text text-center body-1 align-self-center">
                        <a href="tel:+3615858381" style="color: #FFFFFF;">06-1/585-83-81</a>
                      </div>-->
                    </div>
                  </v-img>
                </v-col>
                <v-col cols="12" md="6" class="px-10 py-12">
                  <v-form v-model="loginFormIsValid">
                    <v-row>
                      <v-col cols="12" class="text-center mb-6">
                        <span class="headline">
                          {{ $t('mainNavBar.tracking') }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="loginForm.email"
                                      :label="$t('email')"
                                      :rules="inputRules.emailRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="loginForm.gsmWorksheet"
                                      :label="$t('gsmWorksheetId')"
                                      :rules="inputRules.required"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="loginForm.identifier"
                                      :label="$t('deviceIdentifier')"
                                      :rules="inputRules.required"
                                      :messages="$t('imeiHint')"
                                      :hint="$t('inputMessages.requiredField')"
                                      counter="22"
                                      maxlength="22"
                                      persistent-hint
                                      outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="mt-6">
                        <v-btn color="primary" block :disabled="!loginFormIsValid" @click="login">
                          {{ $t('button.send') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmModal.isVisible" persistent max-width="440px">
      <v-card>
        <v-card-text class="pa-8 text-center">
          <div v-html="$t(`${confirmModal.message}`)"></div>
        </v-card-text>
        <v-card-actions class="pa-4" v-show="!confirmModal.isLoading">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeConfirmModal">
            OK
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          v-show="confirmModal.isLoading"
          indeterminate
          color="primary"
          height="8"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeroTitle from "../../components/titles/HeroTitle.vue";
import { saveToken } from 'src/utils/jwtHelper';

export default {
  name: 'DocTmxTracking',
  components: {
    HeroTitle,
  },
  props: {
    //
  },
  data() {
    return {
      loginFormIsValid: false,
      loginForm: {
        /* email: 'sanyi@jani.sanyi',
        crmRequestId: '102441',
        identifier: '123456789012345',
        email: 'sanyi@kati.sanyi',
        crmRequestId: '102443',
        identifier: '210512103221101',*/
        email: '',
        gsmWorksheet: '',
        crmRequestId: '',
        crmCustomerType: 'VIEWER',
      },
      inputRules: {
        required: [v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      confirmModal: {
        isVisible: false,
        message: 'documentViewer.loginInProgress',
        success: false,
        isLoading: true,
      }
    };
  },
  methods: {
    async login() {
      this.openConfirmModal();
      const loginResponse = await this.$store.dispatch('LoginViewer', this.loginForm);
      if (loginResponse.status === 200) {
        setTimeout(this.successConfirmModal, 2200);
      } else {
        setTimeout(this.notSuccessConfirmModal, 2200);
      }
    },
    openConfirmModal() {
      this.confirmModal.message = 'documentViewer.inprogressAuth';
      this.confirmModal.isLoading = true;
      this.confirmModal.isVisible = true;
    },
    successConfirmModal() {
      this.confirmModal.message = `documentViewer.succAuth`;
      this.confirmModal.success = true;
      this.confirmModal.isLoading = false;
    },
    notSuccessConfirmModal() {
      this.confirmModal.message = 'documentViewer.failedAuth';
      this.confirmModal.success = false;
      this.confirmModal.isLoading = false;
    },
    closeConfirmModal() {
      if (this.confirmModal.success) {
        this.confirmModal.isVisible = false;
        this.$router.push({name: 'home'})
      } else {
        this.confirmModal.isVisible = false;
        this.confirmModal.isLoading = true;
      }
    },
  },
};
</script>

<style scoped>

</style>
